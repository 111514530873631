import axios from 'axios'

axios.defaults.withCredentials = true
axios.interceptors.response.use((res) => {
  return res.data || res
}, (error) => {
  return Promise.reject(error)
})

function postAjaxfile(url, params) {
  var headerrel = {
    'Content-Type': 'multipart/form-data',
  }
  var paramrel = params
  headerrel["Authorization"] = "Bearer " + window.localStorage.getItem('token');
  return axios.post(url, paramrel, {
    headers: headerrel
  })
}

function postAjaxJsonexcel(url, params) {
  var headerrel = {
    'content-type': 'application/json'
  }
  headerrel["Authorization"] = "Bearer " + window.localStorage.getItem('token');
  return axios.post(url, params, {
    headers: headerrel,
    responseType: 'blob'
  });
}

// 发送postajax请求
function postAjaxJson(url, params) {
  var headerrel = {
    'Content-Type': 'application/json'
  }
  headerrel.Authorization = window.localStorage.getItem('token')
  return axios.post(url, params, {
    headers: headerrel
  })
}

// 发送get请求
function getAjaxJson(url, params) {
  var headerrel = {
    'Content-Type': 'application/json'
  }
  headerrel.Authorization = window.localStorage.getItem('token')
  return axios.get(url, params, {
    headers: headerrel
  })
}

// 删除
function delAjaxJson(url, params) {
  var headerrel = {
    'Content-Type': 'application/json'
  }
  headerrel.Authorization = window.localStorage.getItem('token')
  return axios.delete(url, params, {
    headers: headerrel
  })
}

// 修改
function putAjaxJson(url, params) {
  var headerrel = {
    'Content-Type': 'application/json'
  }
  headerrel.Authorization = window.localStorage.getItem('token')
  return axios.put(url, params, {
    headers: headerrel
  })
}

function postAjaxJsonLogin(url, params) {
  var headerrel = {
    'Content-Type': 'application/json'
  }
  headerrel.Authorization = window.localStorage.getItem('token')
  return axios.post(url, params, {
    headers: headerrel
  }).then(res => {
    if(res.code == 10002){
      return window.location.href = '/';
    }
    return res;
  })
}

function filterParams(data) {
  let params = {};
  Object.keys(data).map(o => {
    if ((data[o] || data[o] == 0) && data[o].toString().length > 0) {
      params[o] = data[o];
    }
  })
  return params;
}

var BASEURL = "/api"

export default {
  postAjaxJson: postAjaxJson,
  // 全局域名npm 
  gethttp() {
    return BASEURL
  },

  // 请求例子
  // AdminLogin (data) {
  //   return postAjaxJsonLogin(BASEURL + '/UserModule/tb-user/AdminLogin', data)
  // },

  /* 
     商品分类
  */

  // 商品分类
  GetGoodsCategoryList(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-category/QueryGoodsCategory', data)
  },

  // 添加商品分类
  AddGoodsCategory(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-category/AddGoodsCategory', data)
  },
  // 编辑商品分类
  UpdateAddDeleteGoodsCategory(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-category/UpdateAddDeleteGoodsCategory', data)
  },
  // 删除商品分类
  deleteGoodsCategory(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-category/deleteGoodsCategory', data)
  },

  // 编辑商品分类
  // UpdateGoodsCategory(data) {
  //   return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-category/UpdateGoodsCategory', data)
  // },
  AddGoodsToCategory(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-category/AddGoodsToCategory', data)
  },
  QueryGoodsToCategory(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-category/QueryGoodsToCategory', data)
  },

  QueryGoodsToCategory2(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-category/QueryGoodsCanInCategory', data)
  },



  /* 
     商品列表
  */

  // 商品列表
  GetGoodsListToPage(data) {
    let params = filterParams(data);
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods/GetGoodsListToPage', params)
  },

  // 添加商品
  AddGoods(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods/AddGoods', data)
  },

  // 删除商品
  BatchToDeleteGoods(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods/BatchToDeleteGoods', data)
  },

  // 编辑商品
  UpdateGoods(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods/UpdateGoods', data)
  },

  // 用了上架、下架商品 id status
  UpdateGoods2(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods/UpdateGoods2', data)
  },

  // 聚水潭
  GoodsUploadToErp(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods/GoodsUploadToErp', data)
  },

  // 聚水潭
  GoodsClone(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods/GoodsClone', data)
  },
  
  //同步好食期商品
  SyncHaoShiQiGoods(data) {
    let params = filterParams(data);
    return postAjaxJsonLogin(BASEURL + '/HaoShiQiAdminModule/adolf-haoshiqi/SyncGoodsList', params)
  },



  /* 
     主页图片
  */

  // 查询主页图片
  QueryPic(data) {
    return postAjaxJsonLogin(BASEURL + '/MainPageAdminModule/adolf-main-page-pic/QueryPic', data)
  },

  // 增加主页图片 pic goodsId
  AddPic(data) {
    return postAjaxJsonLogin(BASEURL + '/MainPageAdminModule/adolf-main-page-pic/AddPic', data)
  },

  // 删除主页图片
  DeletePic(data) {
    return postAjaxJsonLogin(BASEURL + '/MainPageAdminModule/adolf-main-page-pic/DeletePic', data)
  },

  // 修改主页图片
  UpdatePic(data) {
    return postAjaxJsonLogin(BASEURL + '/MainPageAdminModule/adolf-main-page-pic/UpdatePic', data)
  },


  /* 
   商品规格
  */
  // 查询商品规格
  GetGoodsSkuToPage(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-sku/GetGoodsSkuToPage', data)
  },

  // 添加商品规格
  AddGoodsSku(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-sku/AddGoodsSku', data)
  },

  // 删除商品规格
  BatchDeleteGoodsSku(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-sku/BatchDeleteGoodsSku', data)
  },

  // 修改商品规格
  UpdateGoodsSku(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-sku/UpdateGoodsSku', data)
  },

  /* 
   商品分组
  */

  // 查询所有商品分组
  QueryGoodsGroups(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-group/QueryGoodsGroups', data)
  },
  // 添加商品分组,添加商品到分组,删除分组商品 商品id(goodsId); 
  UpdateAddDeleteGoodsGroups(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-group/UpdateAddDeleteGoodsGroups', data)
  },
  // 添加商品分组 分组名称(groupName); 商品id:(goodsId)
  AddGoodsGroups(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-group/AddGoodsGroups', data)
  },
  // 删除商品分组 groupId
  deleteGoodsGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-group/deleteGoodsGroup', data)
  },
  // 查询所有可以添加到分组的商品
  QueryGoodsToGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-group/QueryGoodsToGroup', data)
  },

  /* 
   商品限购分组
  */

  // 查询所有商品分组
  QueryGoodsLimitPurchase(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsLimitPurchase/adolf-limit-purchase/QueryGoodsLimitPurchase', data)
  },
  // 添加商品分组,添加商品到分组,删除分组商品 商品id(goodsId); 
  UpdateAddDeleteGoodsLimitPurchaseInfo(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsLimitPurchase/adolf-limit-purchase/UpdateAddDeleteGoodsLimitPurchaseInfo', data)
  },
  // 添加商品分组 分组名称(groupName); 商品id:(goodsId)
  AddGoodsLimitPurchaseGroups(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsLimitPurchase/adolf-limit-purchase/AddGoodsLimitPurchaseGroups', data)
  },
  // 删除商品分组 groupId
  deleteGoodsGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsLimitPurchase/adolf-limit-purchase/deleteGoodsGroup', data)
  },
  // 查询所有可以添加到分组的商品
  QueryGoodsToLimitPurchaseGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsLimitPurchase/adolf-limit-purchase/QueryGoodsToLimitPurchaseGroup', data)
  },

  /* 
     订单列表
  */
  // 订单推送聚水潭 {orderId}
  orderUploadEr(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/orderUploadErp', data)
  },
  //发货{orderId, logisticsNumber,logisticsBusiness}
  orderSend(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/orderSend', data)
  },
  // 更新物流信息
  UpdateLogisticsOrderNumber(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/UpdateLogisticsOrderNumber', data)
  },
  // 查询订单
  GetOrders(data) {
    let params = filterParams(data);
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/QueryOrdersToPageBy', params)
  },
  // 查询订单
  UpdateOrderById(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/UpdateOrderById', data)
  },
  // 售后列表
  GetRefundOrders(data) {
    let params = filterParams(data);
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/GetRefundOrders', params)
  },
  // 修改买家信息：userName, userPhone, addressName
  UpdateOrderUserMessage(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/UpdateOrderUserMessage', data)
  },
  // 导出列表
  OrderOutExcel(data) {
    let params = filterParams(data);
    return axios.post(BASEURL + '/OrderAdminModule/adolf-order/OrderOutExcel', params, {
      headers: {
        Accept: 'application/msexcel',
        'Content-Type': 'application/json',
        // 'Data-Type': 'application/msexcel'
        // 'Data-Type': 'arraybuff'
        // 'Cookie': 'JSESSIONID='+localStorage.getItem('sessionId'),
      }
    })

  },
  // 删除订单
  BatchDeleteOrdersById(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/BatchDeleteOrdersById', data)
  },
  AddRefundMessage(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfGoodsModule/adolf-goods/AddRefundMessage', data)
  },
  AddRemark(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/AddRemark', data)
  },

  // 订单退款相关
  refundOrder(type, data) {
    //TheOrderRefund 确定用户退货成功且商品没有问题后退款
    var types = [
      '/OrderAdminModule/adolf-order/TakeRefundAgree2',
      '/OrderAdminModule/adolf-order/TakeRefundAgree',
      '/OrderAdminModule/adolf-order/TakeRefundRefuse',
      '/OrderAdminModule/adolf-order-status/TheOrderRefund',
      '/OrderAdminModule/adolf-order/TakeRefundRefuse2',
      '/OrderAdminModule/adolf-order/TakeRefundRefuse3',
    ]
    return postAjaxJsonLogin(BASEURL + types[type], data)
  },
  //获取所以地区信息  
  GetAddressList(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfAddressAdminModule/adolf-address/GetAddressList', data)
  },
  //添加不发货地区prov(省),city(市)
  AddNoOrderAddress(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfNoOrderAddressModule/adolf-noorder-address/AddNoOrderAddress', data)
  },

  //删除不发货地区 id
  DeleteNoOrderAddress(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfNoOrderAddressModule/adolf-noorder-address/DeleteNoOrderAddress', data)
  },

  //查询所有不发货地区  
  QueryNoOrderAddress(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfNoOrderAddressModule/adolf-noorder-address/QueryNoOrderAddress', data)
  },
  //查询所有不发货地区  
  UpdateNoOrderAddress(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfNoOrderAddressModule/adolf-noorder-address/UpdateNoOrderAddress', data)
  },


  //查询广播通知  
  QueryRadio(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfRadioModule/adolf-radio-broadcast/QueryRadio', data)
  },
  //删除广播通知   id
  DeleteRadio(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfRadioModule/adolf-radio-broadcast/DeleteRadio', data)
  },
  //添加广播通知   title
  AddRadio(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfRadioModule/adolf-radio-broadcast/AddRadio', data)
  },
  //修改广播内容   id title status 1|0
  UpdateRadio(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfRadioModule/adolf-radio-broadcast/UpdateRadio', data)
  },


  //查询物流公司  
  QueryBusinessList(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfExpressAdminModule/adolf-express/QueryBusinessList', data)
  },
  //删除物流公司   id
  DeleteBusiness(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfExpressAdminModule/adolf-express/DeleteBusiness', data)
  },
  //AddBusiness   name nickName
  AddBusiness(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfExpressAdminModule/adolf-express/AddBusiness', data)
  },
  //修改物流公司   id name nickName
  UpdateBusiness(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfExpressAdminModule/adolf-express/UpdateBusiness', data)
  },


  //查询物流公司  
  QueryRefundAddressList(data) {
    return postAjaxJsonLogin(BASEURL + '/BusinessAdminModule/adolf-business/QueryBusinessList', data)
  },
  //删除物流公司   id
  DeleteRefundAddress(data) {
    return postAjaxJsonLogin(BASEURL + '/BusinessAdminModule/adolf-business/DeleteBusiness', data)
  },
  //AddBusiness   name nickName
  AddRefundAddress(data) {
    return postAjaxJsonLogin(BASEURL + '/BusinessAdminModule/adolf-business/AddBusiness', data)
  },
  //修改物流公司   id name nickName
  UpdateRefundAddress(data) {
    return postAjaxJsonLogin(BASEURL + '/BusinessAdminModule/adolf-business/UpdateBusiness', data)
  },

  // 投诉管理
  //投诉列表   currentPage pageSize beginDate endDate orderId phone status username
  GetComplaintListToPage(data) {
    let params = filterParams(data);
    return postAjaxJsonLogin(BASEURL + '/ComplaintAdminModule/adolf-complaint/GetComplaintListToPage', params)
  },
  //审核投诉 id  feedback feedbackPic
  ExamineComplaint(data) {
    return postAjaxJsonLogin(BASEURL + '/ComplaintAdminModule/adolf-complaint/ExamineComplaint', data)
  },
  //关闭投诉   id
  CloseComplaint(data) {
    return postAjaxJsonLogin(BASEURL + '/ComplaintAdminModule/adolf-complaint/CloseComplaint', data)
  },

  //查询福利页  
  getWelfare(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfWelfareAdminModule/adolf-welfare/getWelfare', data)
  },
  //添加福利页   pic url
  AddWelfare(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfWelfareAdminModule/adolf-welfare/AddWelfare', data)
  },
  //修改福利页   id pic url
  UpdateWelfare(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfWelfareAdminModule/adolf-welfare/UpdateWelfare', data)
  },
  //
  DeleteWelfare(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfWelfareAdminModule/adolf-welfare/DeleteWelfare', data)
  },

  //评价列表   currentPage pageSize 
  GetEvaluateList(data) {
    let params = filterParams(data);
    return postAjaxJsonLogin(BASEURL + '/AdolfEvaluateAdminModule/adolf-evaluate/GetEvaluateList', params)
  },
  //添加评论到商品评论组   comprehensiveRemark status comprehensiveScore evaluateMessage evaluatePics groupId ifShow sort userName userPic
  AddEvaluateToGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfEvaluateAdminModule/adolf-evaluate/AddEvaluateToGroup', data)
  },
  UpdateEvaluateFromGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfEvaluateAdminModule/adolf-evaluate/UpdateEvaluateFromGroup', data)
  },
  DeleteEvaluateFromGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfEvaluateAdminModule/adolf-evaluate/DeleteEvaluateFromGroup', data)
  },


  // 查询所有评论分组
  GetGoodsEvaluateGroupList(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfGoodsEvaluateGroupAdminModule/adolf-goods-evaluate-group/GetGoodsEvaluateGroupList', data)
  },
  // 添加评论分组 分组名称(groupName); 商品id:(goodsId)
  AddGoodsEvaluateGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfGoodsEvaluateGroupAdminModule/adolf-goods-evaluate-group/AddGoodsEvaluateGroup', data)
  },
  // 删除评论分组 groupId
  DeleteGoodsEvaluateGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfGoodsEvaluateGroupAdminModule/adolf-goods-evaluate-group/DeleteGoodsEvaluateGroup', data)
  },
  // 修改评论分组
  UpdateGoodsEvaluateGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfGoodsEvaluateGroupAdminModule/adolf-goods-evaluate-group/UpdateGoodsEvaluateGroup', data)
  },
  QueryGoodsToEvaluateGroup(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods/QueryGoodsToEvaluateGroup', data)
  },

  //渠道管理
  AddGoodsChannel(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-channel/AddGoodsChannel', data)
  },
  DeleteGoodsChannel(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-channel/DeleteGoodsChannel', data)
  },
  GetGoodsChannelList(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-channel/GetGoodsChannelList', data)
  },
  UpdateGoodsChannel(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods-channel/UpdateGoodsChannel', data)
  },

  //快递模板（限发地区模板）
  AddTemplate(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfNoOrderAddressModule/adolf-noorder-address-template/AddTemplate', data)
  },
  DeleteTemplate(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfNoOrderAddressModule/adolf-noorder-address-template/DeleteTemplate', data)
  },
  GetTemplateList(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfNoOrderAddressModule/adolf-noorder-address-template/GetTemplateList', data)
  },
  UpdateTemplate(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfNoOrderAddressModule/adolf-noorder-address-template/UpdateTemplate', data)
  },

  //短信推送
  UpdateMessageSendById(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-message-use-control/UpdateMessageSendById', data)
  },
  QueryMessageSendList(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-message-use-control/QueryMessageSendList', data)
  },


  //user
  AddSysUser(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfUserModule/adolf-sys-user/AddSysUser', data)
  },
  DeleteSysUserById(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfUserModule/adolf-sys-user/DeleteSysUserById', data)
  },
  GetSysUserListToPageBy(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfUserModule/adolf-sys-user/GetSysUserListToPageBy', data)
  },
  UpdateSysUser(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfUserModule/adolf-sys-user/UpdateSysUser', data)
  },
  //role
  AddRole(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfRolePermissionAdminModule/adolf-role/AddRole', data)
  },
  DeleteRole(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfRolePermissionAdminModule/adolf-role/DeleteRole', data)
  },
  getRoleList(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfRolePermissionAdminModule/adolf-role/RoleList', data)
  },
  updateRole(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfRolePermissionAdminModule/adolf-role/updateRole', data)
  },

  //selldata user
  userCreate(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/alipay-user-admin/userCreate', data)
  },
  userList(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/alipay-user-admin/userList', data)
  },
  //selldata role
  queryRole(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/alipay-role/queryRole', data)
  },
  
  
  //menu
  queryMenus(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfRolePermissionAdminModule/adolf-menu-parents/List', data)
  },


  //databoard
  databoardList(data) {
    let params = filterParams(data);
    return postAjaxJsonLogin(BASEURL + '/DataBoardAdminModule/adolf-data-board/QueryMainB', params)
  },
  
  AddGoodsLaunch(data) {
    return postAjaxJsonLogin(BASEURL + '/DataBoardAdminModule/adolf-launch/AddGoodsLaunch', data)
  },
  DeleteGoodsLaunch(data) {
    return postAjaxJsonLogin(BASEURL + '/DataBoardAdminModule/adolf-launch/DeleteGoodsLaunch', data)
  },
  GetGoodsLaunchList(data) {
    let params = filterParams(data);
    return postAjaxJsonLogin(BASEURL + '/DataBoardAdminModule/adolf-launch/GetGoodsLaunchList', params)
  },
  UpdateGoodsLaunch(data) {
    return postAjaxJsonLogin(BASEURL + '/DataBoardAdminModule/adolf-launch/UpdateGoodsLaunch', data)
  },
  GetGoodsGroupList(data) {
    return postAjaxJsonLogin(BASEURL + '/DataBoardAdminModule/adolf-data-board/GetGoodsGroupList', data)
  },
  GetGoodsList(data) {
    return postAjaxJsonLogin(BASEURL + '/DataBoardAdminModule/adolf-data-board/GetGoodsList', data)
  },

  //purchase
  GetPurchaseMoneyList(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-purchase-money/GetPurchaseMoneyList2', data)
  },
  UpdatePurchaseMoney(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-purchase-money/UpdatePurchaseMoney', data)
  },
  AddPurchaseMoney(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-purchase-money/AddPurchaseMoney', data)
  },
  DeletePurchaseMoney(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-purchase-money/DeletePurchaseMoney', data)
  },


  //sign
  signRedeemListToPage(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/sign-redeem/signRedeemListToPage', data)
  },
  addSignRedeem(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/sign-redeem/addSignRedeem', data)
  },
  deleteSignRedeem(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/sign-redeem/deleteSignRedeem', data)
  },
  updateSignRedeem(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/sign-redeem/updateSignRedeem', data)
  },
  signBoardListToPage(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/sign-board/signBoardListToPage', data)
  },
  updateSignBoard(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/sign-board/updateSignBoard', data)
  },

  sendEnergy(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-ant-power-record/GetPowerForAdmin', data)
  },

  //商家券
  activityOrderVoucherCreate(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/adolf-alipay-activity/activityOrderVoucherCreate', data)
  },
  activityOrderVoucherList(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/adolf-alipay-activity/activityOrderVoucherList', data)
  },
  //淘特商品
  getGoodsAllFromTaoTe(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/goods-taoTe/getGoodsAllFromTaoTe', data)
  },

  orderSendToTaoTe(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/orderSendToTaoTe', data)
  },
  generateQrCode(data) {
    return postAjaxJsonLogin(BASEURL + '/AdolfUserModule/adolf-sys-user/generateQrCode', data)
  },
  //查询淘特商品列表
  // {
  //   "s": 1, 
  //   "n": 20, 
  //   "sort": "price:desc" 
  // }
  TTItemList(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/goods-taoTe/TTItemList', data)
  },
  TTItemDetail(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/goods-taoTe/TTItemDetail', data)
  },
  // taote goods add
  // {itemId, skuIds: ['','']}
  TTItemAdd(data) {
    return postAjaxJsonLogin(BASEURL + '/GoodsAdminModule/adolf-goods/AddGoods/two', data)
  }, 


  // 会员卡模块
  vipCardList(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/vip-card/list', data)
  },
  vipCardUpdate(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/vip-card/update', data)
  },
  
  orderSign(data) {
    return postAjaxJsonLogin(BASEURL + '/OrderAdminModule/adolf-order/orderSign', data)
  },

  //---所有小程序订单管理---

  //查询所有小程序主体
  appletsSubjects(data) {
    return postAjaxJsonLogin(BASEURL + '/admin/alipay-applets-subject/appletsSubjects', data)
  },
  //分页条件查询所有小程序订单
  getOrderListToPage(data) {
    let params = filterParams(data);
    return postAjaxJsonLogin(BASEURL + '/admin/alipay-order/getOrderListToPage', params)
  },
}