
Date.prototype.format = function(format) {
  let string = format;
  const o = {
    'M+': this.getMonth() + 1, // month
    'd+': this.getDate(), // day
    'h+': this.getHours(), // hour
    'm+': this.getMinutes(), // minute
    's+': this.getSeconds(), // second
    'q+': Math.floor((this.getMonth() + 3) / 3), // quarter
    S: this.getMilliseconds(), // millisecond
  };
  if (/(y+)/.test(string)) {
    string = string.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  Object.keys(o).forEach((key) => {
    if (new RegExp(`(${key})`).test(string)) {
      string = string.replace(new RegExp(RegExp.$1, 'g'), RegExp.$1.length === 1 ? o[key] : `00${o[key]}`.substr(`${o[key]}`.length));
    }
  });
  return string;
};

const sysInfoMap = {
	adf: 		{title: '阿道夫', },
	langsha: 	{title: '浪莎', },
	leeee: 		{title: '浪莎福利社', },
	chigo: 		{title: '志高', },
	ocak: 		{title: '欧扎克', },
	ocak2: 		{title: '欧扎克2', },
	frestec: 	{title: '新飞', },
	frestec2: 	{title: '新飞福利社', },
	swisse: 	{title: '新飞小家电', },
	paws: 		{title: '博氏', },
	ganyuan: 	{title: '甘源', },
	lzyx: 		{title: '绿账优选', },
	lzyxplus: 	{title: '绿账优选旗舰', },
	xnssq: 		{title: '新年上上签', },
	wlj: 		{title: '王老吉', },
	bluemoon: 	{title: '蓝月亮', },
	breeze: 	{title: '清风', },
	kobach: 	{title: '康巴赫', },
	sxx: 		{title: '思氧', },
	konka: 		{title: '康佳', },
	kawu: 		{title: '芝韶', },
	kawu2: 		{title: '卡屋', },
	milkground: {title: '妙可蓝多', },
	daxidi: 	{title: '大希地', },
	liby: 		{title: '立白', },
	magicore: 	{title: '魔核', },
	lotor: 		{title: '小浣熊', },
	lotorx: 	{title: '小浣熊福利社', },
	xuanma: 	{title: '轩妈', },
	cchoice: 	{title: '采琪采', },
	pzh: 		{title: '片仔癀', },
	lp: 		{title: '蓝漂', },
	a1: 		{title: 'a1', },
	beneunder: 	{title: '蕉下', },
	maizimon: 	{title: '杜秀', },
	sdzx: 		{title: '时代甄选', },
	wugumofang: {title: '五谷磨房', },
	sanquan: 	{title: '五谷磨房惊喜社', },
	pisen: 		{title: '品胜', },
	juaolai: 	{title: '聚奥莱', },
	yth: 		{title: '耀特惠', },
	weinuo: 	{title: '卫诺', },
	lehe: 		{title: '南方生活家纺生活馆', },
	drcheese: 	{title: '奶酪博士', },
	chiaoe: 	{title: '巧益', },
	mayflower: 	{title: '五月花', },
	qwwz: 		{title: '青蛙王子', },
	xianhuaji: 	{title: '家居花全国', },
	jxc: 		{title: '居小厨', },
	sdyj: 		{title: '六桂福珠宝旗舰店', },
	mengyuan: 	{title: '孟垣', },
	olayks: 	{title: '银时代珠宝', },
	shanben: 	{title: '山本', },
	shunong: 	{title: '数字农业', },
	mekine: 	{title: '美姿媛', },
	ryo: 		{title: '安渼沁旗舰店', },
	plst: 		{title: '普莉斯町', },
	malata: 	{title: '万利达', },
	malata2: 	{title: '万利达福利社', },
	malata3: 	{title: '万利达惊喜社', },
	xye: 		{title: '香瑜尔', },
	aier: 		{title: 'aier', },
	hy: 		{title: '金昱利', },
	centrum: 	{title: '善存', },
	caltrate: 	{title: '贝美优佳旗舰店', },
	bekang: 	{title: '贝伊康', },
	ninghuan: 	{title: '凝欢旗舰店', },
	sukgarden: 	{title: '蔬果园', },
	blackbaby: 	{title: '乾龙茶叶', },
	einb: 		{title: '休洗', },
	ysxs: 		{title: '袁氏向山', },
	gsht: 		{title: '高氏禾田', },
	gm: 		{title: '爆款优选', },
	jingfu: 	{title: '靖脯旗舰店', },
	fanzhen: 	{title: '梵贞', },
	zhangxiaoquan:{title: '张小泉', },
	lenovo: 	{title: '联想', },
	lenovox: 	{title: '联想耳机', },
	aerdi: 		{title: '阿尔帝', },
	hexian: 	{title: '良制品', },
	yuxiaoxian: {title: 'G.DUCK', },
	jinpan: 	{title: '锦盼', },
	reddragonfly:{title: '红蜻蜓家纺', },
	zidanbaoluo:{title: '子丹保罗', },
	youborui: 	{title: '优博瑞', },
	xiaoyeriji: {title: '小野日记', },
	baikouzan: 	{title: '百口赞', },
	kispa: 		{title: '好爸爸', },
	miss: 		{title: '蜜丝', },
	sylvia: 	{title: '香维娅', },
	libyxbb: 	{title: '立白小白白', },
	seago: 		{title: '赛嘉', },
	dudao: 		{title: '赞缘阁', },
	keke: 		{title: '科客', },
	nintaus: 	{title: '玛莉吉亚', },
	lpzw: 		{title: '享柠旗舰店', },
	kawaqi: 	{title: '冰泉', },
	aixiaojin: 	{title: '哎小巾', },
	liangzhi: 	{title: '良制', },
	zhihushi: 	{title: '纸护仕', },
	superb: 	{title: '超威', },
	jihuo: 		{title: '恒约个护', },
	haiqin: 	{title: '海琴', },
	beilaochen: {title: '露丝', },
	qiyinjie: 	{title: '七音界', },
	xiaohu: 	{title: '皇家小虎', },
	xuting: 	{title: '绪婷', },
	aucma: 		{title: '澳柯玛', },
	huiyuan: 	{title: '麦麦优选', },
	youxuan: 	{title: '优选特惠', },
	tehuijia: 	{title: '特惠家', },
	haowu: 		{title: '好物推荐官', },
	vip: 		{title: '会员专享权益', },
	sysz: 		{title: '上医时珍', },
	softto: 	{title: '索芙特', },
	jiahesi: 	{title: '嘉赫斯', },
	jierou: 	{title: '小浣熊家电', },
	dom: 		{title: 'DOM旗舰店', },
	qiaotou: 	{title: '桥头', },
	findbud: 	{title: '芽觅', },
	yinti: 		{title: '喑啼', },
	okady: 		{title: 'Dinaicc', },
	disney: 	{title: '迪士尼', },
	wyeth: 		{title: '花花会', },
	joce: 		{title: 'Joce数码', },
	wuyi: 		{title: '武夷智圣者', },
	jomilk: 	{title: '神气水牛旗舰店', },
	treelang: 	{title: '极盏', },
	zaixiaobao: {title: '仔小煲', },
	yxvip: 		{title: '优选会员店', },
	shuyu: 		{title: '耀淘淘', },
	mgz: 		{title: 'MGZ旗舰店', },
	xiaoxin: 	{title: '中银金行', },
	vsource: 	{title: 'Vsource', },
	tetaotao: 	{title: '特惠优选', },
	ttsd: 		{title: '特淘时代', },
	yaotaotao: 	{title: '耀你淘', },
	douwang: 	{title: '抖王', },
	cobinne: 	{title: '康贝宁', },
	fifo: 		{title: '啡否', },
	jiangzhongzhiyao:{title: '江中制药', },
	puke: 		{title: '朴可', },
	wansuijiadao:{title: '万岁驾到', },
	honingdrill:{title: 'HoningDrill', },
	pinhuan: 	{title: '品唤', },
	xieyuesanxing:{title: '斜月三星', },
	yigeshapa: 	{title: '周大生', },
	xiangshizhe:{title: '简壳', },
	jianke: 	{title: '周六福旗舰店', },
	xihegou: 	{title: '吾须里会员店', },
	teaitao: 	{title: '绿账优淘', },
	thsd: 		{title: '特惠时代', },
	wayme: 		{title: 'wayme', },
	wuyubense: 	{title: '物语本色', },
	retu: 		{title: '热图', },
	songxintong:{title: '松信通', },
	tongbu: 	{title: '3C数码', },
	chaoying: 	{title: '超盈数码', },
	seaklam: 	{title: '海肌兰', },
	hewn: 		{title: '华尔文奈', },
	bqb: 		{title: 'BQB', },
	shuangxilongfeng:{title: '双喜龙凤珠宝', },
	ijarl: 		{title: '舒棉', },
	fonce: 		{title: '梵西', },
	guoyuan: 	{title: '国圆', },
	yijia: 		{title: 'OfficeNoki', },
	pwu: 		{title: '朴物大美', },
	recook: 	{title: '左家右厨', },
	daminy: 	{title: '丹米妮', },
	seyee: 		{title: '沢野良品', },
	yousu: 		{title: '悠素', },
	lewen: 		{title: '唐魅可', },
	zchuien: 	{title: '早春', },
	zhongqing: 	{title: '钟情', },
	xianyijiao: {title: '威飘', },
	loveherbeauty:{title: 'LoveHerBeauty', },
	quanriji: 	{title: '泉日记', },
	huilingyang:{title: '徽羚羊户外', },
	shengping: 	{title: '晟平精选', },
	runqiyue: 	{title: '润七月美护', },
	jingdufeng: {title: '景都凤', },
	suxinyi: 	{title: '古名珠宝', },
	alex: 		{title: 'IMANROUG', },
	damabifu: 	{title: '大马碧富', },
	tianse: 	{title: '天色美妆', },
	saidale: 	{title: '鞋百乐', },
	royal: 		{title: '皇家', },
	hongxing: 	{title: '洪星', },
	homemycube: {title: '居立方', },
	chinagold: 	{title: '中国黄金', },
	jiamao: 	{title: '嘉贸', },
	roudafu: 	{title: '柔达肤', },
	hengbaili: 	{title: '亨佰丽', },
	sjs: 		{title: '艾美特', },
	basni: 		{title: '邦仕尼', },
	baijunhui: 	{title: '百君汇', },
	lalpina: 	{title: '唐朵琪', },
	yueerde: 	{title: '粤尔德', },
	delekang: 	{title: '得乐康', },
	jinguque: 	{title: '古朵猫', },
	skybear: 	{title: '天空熊', },
	junai: 		{title: '公牛', },
	tianqi: 	{title: '田七', },
	coobowe: 	{title: 'COOBOWE', },
	mamfen: 	{title: '360', },
	hyundai: 	{title: 'HYUNDAI', },
	mester: 	{title: '美斯特', },
	qingfenghuayu:{title: '清风花语', },
	zhibai: 	{title: '直白', },
	tineco: 	{title: '添可', },
	ecovacs: 	{title: '科沃斯', },
	miboi: 		{title: 'MIBOI米博', },
	macaiiroos: {title: '迈卡罗', },
	xigemeng: 	{title: '西格蒙', },
	temai: 		{title: '特卖手机', },
	mooleboo: 	{title: '摩步', },
	yishuangda: {title: '梦洁家纺', },
	jingxu: 	{title: '静絮', },
	rscrjcs: 	{title: '菜瓜精选', },
	xiaoyou: 	{title: '啸优', },
	yipinyinxiang:{title: '壹品印象', },
	baixiang: 	{title: '百享心选', },
	germ: 		{title: 'germ', },
	zhouliufu: 	{title: '周六福珠宝', },
	wenxi: 		{title: '闻溪好物', },
	zheyue: 	{title: '哲岳臻品', },
	lg: 		{title: 'LG', },
	quanlin: 	{title: '泉林本色', },
	wuliangye: 	{title: '五粮液', },
	mendale: 	{title: '梦洁家纺', },


	selldata: {title: '数据', },


	todaytail: 	{title: '今日淘尾货', },
	today99: 	{title: '今日9块9', },
	sqhl: 		{title: '森趣护理', },
}

const setFavicon = (type) => {
	removeFavicon();
	var link = document.createElement('link');
	link.type = 'image/x-icon';
	link.rel = 'icon';
	link.href = `/logo/${type}.png`;
	document.getElementsByTagName('head')[0].appendChild(link);
}

const removeFavicon = () => {
	var links = document.getElementsByTagName('link');
	var head = document.getElementsByTagName('head')[0];
	for (var i = 0; i < links.length; i++) {
	  if (links[i].getAttribute('rel') === 'icon') {
	    head.removeChild(links[i]);
	  }
	}
}

window.isTestEnv = () => {
	return window.location.host.includes('test');
}

window.getSysType = () => {
	if(window.location.host.includes('kawu.yaoliandong.com')) {
		return 'kawu2'
	}
	const host = window.location.host.split('.')[0];
	if(host.includes('localhost')) return 'lzyx';
	return host.split('-')[0];
}

//判断是否有优惠时效
window.hasGoodExpireUI = () => {
	return ['lzyx', 'todaytail', 'today99'].includes(window.getSysType());
}
//判断是否有渠道功能
window.hasChannelUI = () => {
	return ['lzyx', 'todaytail', 'today99'].includes(window.getSysType());
}
//判断蚂蚁能量显示
window.hasAntUI = () => {
	return !['shunong', 'tetaotao', 'ttsd', 'thsd', 'yaotaotao', 'xihegou', 'tehuijia', 'retu', 'songxintong', 'tongbu', 'chaoying'].includes(window.getSysType());
}
//判断淘特商品显示
window.hasTaoteUI = () => {
	// return true
	return ['tetaotao', 'ttsd', 'thsd', 'teaitao', 'tehuijia'].includes(window.getSysType());
}
//判断ERP显示
window.hasErpUI = () => {
	return !['shunong', 'tetaotao', 'ttsd', 'thsd', 'yaotaotao', 'tehuijia', 'retu', 'songxintong', 'tongbu', 'chaoying'].includes(window.getSysType());
}
//五福
window.hasWufuUI = () => {
	return ['adf', 'xuanma', 'liby', 'sanquan'].includes(window.getSysType());
}
//假券功能
window.hasFakeCouponUI = () => {
	// return true;
	return ['teaitao', 'yth', 'sdzx', 'thsd', 'huiyuan'].includes(window.getSysType());
}
//判断是否有快递功能
window.hasExpressTemplateUI = () => {
	//默认全部支持快递模板
	return true;
	// return ['lzyx', 'langsha', 'frestec'].includes(window.getSysType());
}

window.setSysInfo = () => {
	const host = window.location.host.split('.')[0];
	if(host.includes('localhost')) return {type: 'langsha', title: ''};
	const isTestEnv = host.includes('test');
	const sysType = window.getSysType();
	const sysInfo = sysInfoMap[sysType] || sysInfoMap.langsha;
	const title = `${sysInfo.title}${isTestEnv ? '(测试)' : ''}`;
	document.title = title;
	setFavicon(sysType)
	return {
		type: sysType,
		title
	}
}
window.isGuest = () => {
	let userInfo = window.localStorage.getItem('userInfo');
	if(!userInfo || userInfo == 'undefined') return true;
    userInfo = JSON.parse(userInfo)
    if(!userInfo.roleMessage || userInfo.roleMessage.operation != 1) return true;
}
window.isAdmin = () => {
	let userInfo = window.localStorage.getItem('userInfo');
	if(!userInfo || userInfo == 'undefined') return false;
    userInfo = JSON.parse(userInfo)
    if(userInfo && userInfo.role == 1) return true;
}
window.getUserInfo = () => {
	let userInfo = window.localStorage.getItem('userInfo');
	if(!userInfo || userInfo == 'undefined') return null;
    return JSON.parse(userInfo)
}

window.getMenus = () =>{
	let menus = window.localStorage.getItem('menus');
	if(!menus || menus == 'undefined') return [];
    return JSON.parse(menus)
}