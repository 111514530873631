import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import api from './api'
// 第三方树形组件
import TreeTable from 'vue-table-with-tree-grid'
import Global from './Global';

// 全局挂载
Vue.prototype.$api = api
Vue.config.productionTip = false;

// 导入全局样式
import '@/assets/css/global.css'
// 导入字体图标
import '@/assets/fonts/iconfont.css'

Vue.component('tree-table', TreeTable)


Vue.prototype.transferMainPic = (url) => {
	if(/\[.*\]/.test(url)){
		return JSON.parse(url)
	}
	return url.split(',')
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");