import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // 例子
  {
    path: '/',
    name: 'login',
    component: () => import('@/components/Login.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    redirect: '/welcome',
    component: () => import('@/components/Home.vue'),
    children: [
      // 欢迎页
      {
        path: '/welcome',
        name: 'welcome',
        component: () => import('@/components/Welcome.vue'),
      },
      //  商品管理
      {
        path: '/goods',
        name: 'goods',
        component: () => import('@/components/goods/goods.vue'),
      },
      {
        path: '/purchase',
        name: 'purchase',
        component: () => import('@/components/goods/purchase.vue'),
      },
      //  渠道管理
      {
        path: '/channel',
        name: 'channel',
        component: () => import('@/components/goods/channel.vue'),
      },
      //  商品组合
      {
        path: '/groups',
        name: 'groups',
        component: () => import('@/components/goods/groups.vue'),
      },
      //  商品限购
      {
        path: '/limits',
        name: 'limits',
        component: () => import('@/components/goods/limits.vue'),
      },
      // 商品分类
      {
        path: '/cate',
        name: 'cate',
        component: () => import('@/components/goods/cate.vue'),
      },
      // 订单管理
      {
        path: '/order',
        name: 'order',
        component: () => import('@/components/order/order.vue'),
      },
      // 售后管理
      {
        path: '/afterSale',
        name: 'afterSale',
        component: () => import('@/components/afterSale/afterSale.vue'),
      },
      // 投诉管理
      {
        path: '/feedback',
        name: 'feedback',
        component: () => import('@/components/afterSale/feedback.vue'),
      },
      // 用户管理
      {
        path: '/user',
        name: 'user',
        component: () => import('@/components/user/user.vue'),
      },
      {
        path: '/role',
        name: 'role',
        component: () => import('@/components/user/role.vue'),
      },
      // 看板用户管理
      {
        path: '/datauser',
        name: 'datauser',
        component: () => import('@/components/datauser/user.vue'),
      },
      {
        path: '/datarole',
        name: 'datarole',
        component: () => import('@/components/datauser/role.vue'),
      },
      // 添加商品
      {
        path: '/addGoods',
        name: 'addGoods',
        component: () => import('@/components/goods/addGoods.vue'),
      },
      // 添加商品组合
      {
        path: '/addGroups',
        name: 'addGroups',
        component: () => import('@/components/goods/addGroups.vue'),
      },
      // 添加商品上钩
      {
        path: '/addLimits',
        name: 'addLimits',
        component: () => import('@/components/goods/addLimits.vue'),
      },
      // 添加商品分类
      {
        path: '/addCate',
        name: 'addCate',
        component: () => import('@/components/goods/addCate.vue'),
      },
      // 商品规格
      {
        path: '/SpeGoods',
        name: 'SpeGoods',
        component: () => import('@/components/goods/SpeGoods'),
      },
      // 商品规格
      {
        path: '/addSpeGoods',
        name: 'addSpeGoods',
        component: () => import('@/components/goods/addSpeGoods'),
      },
      // 限发区管理
      {
        path: '/area',
        name: 'area',
        component: () => import('@/components/other/area'),
      },
      // 限发区管理
      {
        path: '/expressTemplate',
        name: 'expressTemplate',
        component: () => import('@/components/other/expressTemplate'),
      },
      // 系统广播
      {
        path: '/broadcast',
        name: 'broadcast',
        component: () => import('@/components/other/broadcast'),
      },
      {
        path: '/company',
        name: 'company',
        component: () => import('@/components/other/company'),
      },
      {
        path: '/refundAddress',
        name: 'refundAddress',
        component: () => import('@/components/other/refundAddress'),
      },
      {
        path: '/welfare',
        name: 'welfare',
        component: () => import('@/components/other/welfare'),
      },
      {
        path: '/addWelfare',
        name: 'addWelfare',
        component: () => import('@/components/other/addWelfare'),
      },
      {
        path: '/sms',
        name: 'sms',
        component: () => import('@/components/other/sms'),
      },{
        path: '/vipcard',
        name: 'vipcard',
        component: () => import('@/components/other/vipcard'),
      },{
        path: '/commentGoods',
        name: 'commentGoods',
        component: () => import('@/components/comment/commentGoods'),
      },{
        path: '/comment',
        name: 'comment',
        component: () => import('@/components/comment/comment'),
      },{
        path: '/addComment',
        name: 'addComment',
        component: () => import('@/components/comment/addComment'),
      },{
        path: '/addCommentGoods',
        name: 'addCommentGoods',
        component: () => import('@/components/comment/addCommentGoods'),
      },{
        path: '/databoard',
        name: 'databoard',
        component: () => import('@/components/databoard/list'),
      },{
        path: '/launch',
        name: 'launch',
        component: () => import('@/components/databoard/launch'),
      },{
        path: '/dataall',
        name: 'dataall',
        component: () => import('@/components/databoard/all'),
      },{
        path: '/sign',
        name: 'sign',
        component: () => import('@/components/other/sign'),
      },{
        path: '/bcoupon',
        name: 'bcoupon',
        component: () => import('@/components/bcoupon/bcoupon'),
      },{
        path: '/bcouponcom',
        name: 'bcouponcom',
        component: () => import('@/components/bcoupon/bcouponCom'),
      },{
        path: '/addbcoupon',
        name: 'addbcoupon',
        component: () => import('@/components/bcoupon/addBcoupon'),
      },
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
